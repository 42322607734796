html{
  width: 100%;
  height: 100%;
}

#root{
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* ** {
  padding: 0;
  margin: 0;
}

*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  /*Background*/
  background: #bdbdbd;
  border-radius: 5px;
  width: 16px;
}

*::-webkit-scrollbar-thumb {
  /*barra di scorrimento*/
  background-color: #424242;
  border-radius: 10px;
  /*width: 10px;*/
  border: 4px solid #bdbdbd;
}

.scroller {
  width: 16px;
  /*background: #bdbdbd;*/
  border-radius: 3px;
  overflow-y: hidden;
  overflow-x: hidden;
  scrollbar-color: #424242 #BDBDBD;
}