.machineSettingsPopUp {


  .content {
    display: flex;
    flex-direction: column;
    padding: 0;

    .general {
      padding: 20px;
      display: flex;
      flex-direction: column;

      .emailAlert {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 5px;
        justify-content: space-between;
      }
    }

    .sharing {
      padding: 20px;
      display: flex;
      flex-direction: column;

      .sharingComponents {
        margin-top: 10px;
      }

      .shareComponent {
        padding: 5px;
        margin-bottom: 10px;
        min-width: 350px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      .addShareComponent {
        padding: 5px;
        min-width: 350px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
