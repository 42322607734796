@import '../../../App';

.plant {
  position: relative;
  min-width: 350px;
  min-height: 150px;
  text-align: center;
  border-radius: 20px !important;
  border: 2.5px solid #ab3022;
  padding: 10px;


  @include media("<mobile") {
    flex: 1;
  }


  .content {
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border-radius: 19px;

    &:hover {
      background: rgba(255, 255, 255, .18);
    }
  }
}