.userPopUp {
  position: absolute !important;
  right: 20px !important;
  top: 60px !important;
  left: auto !important;
  bottom: auto !important;

  z-index: 3000;
  width: 250px;

  transition: 0.3s !important;

  overflow: hidden;

  .action {
    width: 100%;
    position: absolute;
    bottom: 20px;
    z-index: 10;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
}

.userPopUpOpen {
  height: 160px;
  box-shadow: 2px 2px 15px 7px rgba(0, 0, 0, .5) !important;
}

.userPopUpClose {
  height: 0;
  padding: 0;
}

.userPopUp .container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  height: 160px;
}

.userPopUp .avatar {
  z-index: 20;

}

.userPopUp .username {
  z-index: 20;

}

.userPopUp .logoutUser {
  flex: 1;
  margin: 0 10px;
}

.userPopUp .email {
  font-size: 0.8rem;
  color: #dbdbdb;
  z-index: 20;
}