.plants {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  //grid-template-columns: repeat(6, auto);
  //grid-auto-rows: 140px;
  //grid-auto-flow: row;
  //grid-gap: 20px;
  //justify-items: stretch;

  & > * {
    margin: 10px;
  }
}