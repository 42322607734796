
.ticketDialog {


  .dialogTitle {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  .dialogContent {
    display: flex;
    flex-direction: column;

    .department {
      padding: 10px 10px 0 10px;
      display: flex;
      flex-direction: row;
      align-content: flex-end;
      align-items: flex-end;
    }

    .mainText {
      padding: 0 10px;
    }

    .rating {
      margin: 0 10px;
      display: flex;
      align-items: flex-end;
    }

    .closedTimestamp{
      //margin: 5px 10px 0 10px;

    }

    .ratingDescription{
      margin: 5px 10px 0 10px;

    }
  }
}
