.app {
  height: 100%;
  width: 100%;
}


$mainColor: #953632;
$mediaMaxBpLittleScreen: 600px;
$mediaMaxBpMobile: 960px;
$mediaMaxBpTablet: 1550px;
$mediaMaxBpDesktop: 1800px;

@function translate-media-condition($c) {
  $condMap: (
    "screen": "only screen",
    "print": "only print",
    "retina": "(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-device-pixel-ratio: 1.5), (min-resolution: 120dpi)",
    ">desktop": "(min-width: #{$mediaMaxBpDesktop + 1})",
    "<desktop": "(max-width: #{$mediaMaxBpDesktop})",
    ">tablet": "(min-width: #{$mediaMaxBpTablet + 1})",
    "<tablet": "(max-width: #{$mediaMaxBpTablet})",
    ">mobile": "(min-width: #{$mediaMaxBpMobile + 1})",
    "<mobile": "(max-width: #{$mediaMaxBpMobile})",
    ">littleScreen": "(min-width: #{$mediaMaxBpLittleScreen + 1})",
    "<littleScreen": "(max-width: #{$mediaMaxBpLittleScreen})"
  );
  @return map-get( $condMap, $c );
}

// The media mixin
@mixin media($args...) {
  $query: "";
  @each $arg in $args {
    $op: "";
    @if ( $query != "" ) {
      $op: " and ";
    }
    $query: $query + $op + translate-media-condition($arg);
  }
  @media #{$query}  { @content; }
}



.boot {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: #202125;

  img {
    //width: 15%;
    height: 10%;
    animation: heartbeat 1s infinite;
    transform: scale(1.1);
    animation-direction: alternate;
    //margin-bottom: 100px;
  }

}


@keyframes heartbeat {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.1);
    }
}
