.settings {
  width: 100%;
  height: 100%;
  padding: 10px 7px;
  display: flex;



  .menu {
    padding: 0 10px;
    flex: 1;
    width: 15%;

    .navigationButton {
      margin: 5px 0;
    }
  }

  .settingsContent{
    flex: 7;
  }
}
