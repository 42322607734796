.document {
  margin: 0 0 5px 0;
  padding: 0 10px 5px 10px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #595959;
  width: 100%;
  height: 45px;
  align-items: center;

  .title {
    text-overflow: ellipsis;
    max-width: 370px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    position: relative;
    top: 1px;
  }
}
