.dataView {
  height: 100%;
  min-height: 0;

  ::-webkit-scrollbar {
    display: none;
  }


  .stepper {
    padding: 10px;

    .step {
      padding: 10px 10px;
      border-radius: 7px;

    }

    .completedStep {

      &:hover {
        background-color: rgba(255, 255, 255, .08);
      }
    }

    .saveFavFilter {
      padding: 10px 10px;
      margin: 0 0 0 0;
    }
  }

  .contentData {

    height: calc(100% - 66px);
    min-height: 0;

    overflow: auto;

    .filters {


      .typeOne {
        padding: 10px;
        display: flex;
        position: relative;


      }

      .textField {
        width: 350px;
        margin: 0 15px 10px 0;

        .MuiFormControl-marginDense {
          margin: 0;
        }
      }

      .visualizationType{
        width: 350px;
        margin: 0 15px 10px 0;
      }

      .button {
        width: 170px;
        margin: 0 10px 10px 0;

      }

      .textFieldMultiple {
        width: 350px;
        margin: 0 15px 10px 0;

        .MuiFormControl-marginDense {
          margin: 0;
        }
      }
    }

    .data {
      //display: flex;
      //flex-direction: row;
      padding: 10px;

      ::-webkit-scrollbar {
        display: block;
      }

      .rdt_TableHeadRow {
        background-color: rgba(0, 0, 0, .45);
      }

      .rdt_Pagination {
        background-color: rgba(0, 0, 0, .30);
      }
    }
  }

}