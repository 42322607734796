.ticket {
  margin: 0 0 5px 0 !important;
  padding: 0 10px 5px 10px !important;
  display: flex !important;
  flex-direction: row !important;
  border-bottom: 1px solid #595959 !important;
  width: 100% !important;
  height: 45px !important;
  justify-content: flex-start !important;

  .title {
    text-align: start;
    text-overflow: ellipsis;
    max-width: 370px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    position: relative;
    top: 1px;
  }
}
