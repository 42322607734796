@import '../../App';

.login {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .loginBody {

    //border: 1px solid rgba(158, 158, 158, 0.5);
    //box-shadow: 3px 3px 15px 4px $mainColor;
    background-color: #424242;
    border-radius: 25px;
    //background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(30px); /* This will produce the blur */
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 80px rgba(0, 0, 0, 0.2);
    //min-height: 400px;

    display: flex;
    position: relative;
    margin: 50px;
    flex-direction: row;

    .logo {
      flex: 1 1 50%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 20px;

      img {
        width: 100%;
        max-width: 300px;
      }
    }

    .auth_form {
      position: relative;
      flex: 1 1 50%;
      width: min-content;
      min-width: 350px;
      padding: 10px;
      display: flex;
      //justify-content: center;
      align-items: center;
      flex-direction: column;

      form{
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
      }

      .text_field {
        max-width: 300px;
      }


      .action {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 10px 0;
        align-items: center;
        justify-content: flex-end;

        .login_button {
          //width: 90%;
          width: 50%;
          margin: 5px 15px 0 0;
        }
      }

    }
  }
}

@media (max-width: 900px) {
  .login .loginBody {
    flex-direction: column;

    .auth_form {
      width: 100%;
    }

  }


}