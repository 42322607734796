@import '../../App';

.signUp {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .signUpBody {
    min-height: 35%;
    //border: 1px solid rgba(158, 158, 158, 0.5);
    //box-shadow: 3px 3px 15px 4px $mainColor;
    background-color: #424242;
    border-radius: 25px;
    //background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(30px); /* This will produce the blur */
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 80px rgba(0, 0, 0, 0.2);

    //border-radius: 10px;
    display: flex;
    position: relative;
    margin: 50px;
    flex-direction: row;


    .logo {
      order: 2;
      flex: 1 1 50%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 20px;

      img {
        width: 100%;
        max-width: 300px;
      }
    }

    .auth_form {
      order: 1;
      position: relative;
      flex: 1 1 50%;
      width: min-content;
      min-width: 350px;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .text_field {
        max-width: 300px;
        margin: 0 0 10px 0;
      }


      .action {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 10px 20px 10px 20px;

        .login_button {
          //width: 90%;
          //margin: 10px 10px 10px 10px;
        }
      }

      .registration_button {
        display: inline-block;
        //position: absolute;
        left: 30px;
      }
    }
  }
}

@media (max-width: 900px) {
  .signUp .signUpBody {
    flex-direction: column;

    .logo{

      order: 1;
    }
    .auth_form {
      order: 2;
      width: 100%;
    }

  }


}