@import '../../../App';


.addUserPopUp {


  .auth_form {
    order: 1;
    position: relative;
    flex: 1 1 50%;
    width: min-content;
    min-width: 350px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .text_field {
      max-width: 300px;
      margin: 0 0 10px 0;
    }


    .action {
      display: flex;
      flex-direction: row;
      width: 100%;
      padding: 10px 20px 10px 20px;

      .login_button {
        //width: 90%;
        //margin: 10px 10px 10px 10px;
      }
    }

    .registration_button {
      display: inline-block;
      //position: absolute;
      left: 30px;
    }
  }
}


//@media (max-width: 900px) {
//  .signUp .signUpBody {
//    flex-direction: column;
//
//    .logo {
//
//      order: 1;
//    }
//
//    .auth_form {
//      order: 2;
//      width: 100%;
//    }
//
//  }
//
//
//}
