.dashboard {
  height: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .content {
    display: flex;

    .extra {
      padding: 10px 0;
      width: 500px;
      height: 100%;
      display: flex;
      flex-direction: column;
      max-height: 1500px;

      .oldTicket{
        margin-left: 10px;
        min-height: 35px !important;
      }

      .container {
        margin-bottom: 10px;
        min-height: 43px;
        transition: 0.35s;
        //max-height: 100px;

        .btn {
          width: 100%;
          display: flex;
          padding: 5px 0 5px 8px;
          justify-content: space-between;

          .title {
          }
        }

        .elements {
          display: flex;
          flex-direction: column;
          overflow: auto;
          height: calc(100% - 43px);
          padding-top: 5px;
        }

        .addDocument {
          margin: auto 5px 5px 5px;
          //justify-self: flex-end;
        }
      }

      .open {
        height: 100%;
      }

      .close {
        height: 0;
        overflow: hidden;

      }
    }
  }

  .skeleton {
    padding: 10px;
    display: flex;
    flex-direction: row;

    .element {
      margin: 0 10px 10px 0;
      border-radius: 5px;
    }
  }


  .stepper {
    padding: 10px;

    .step {
      padding: 10px 10px;
      border-radius: 7px;

    }

    .completedStep {

      &:hover {
        background-color: rgba(255, 255, 255, .08);
      }
    }

    .editDashboard {
      padding: 10px 10px;
      box-shadow: none;
      margin: 0;
      border-radius: 4px;
      //transition: .5s;
    }
  }

  .gridLayout {
    height: 100%;


    .react-resizable-handle::after {
      width: 7px;
      height: 7px;
      border-right: 2px solid #FFF;
      border-bottom: 2px solid #FFF;
    }
  }


}
