.newWidgetDialog{


  .content{
    min-width: 508px;

    .event{
      width: 100%;
    }

    .firstRow{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: stretch;
    }


  }
}
