@import '../App';

.layout {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;


  .appBar {
    background-color: $mainColor;

    .toolBar {
      padding: 0 10px 0 10px;
      display: flex;
      flex-direction: row;

      .left {
        flex: 1;
        display: flex;
        flex-direction: row;
        //padding: 0 0 0 100px;
        position: relative;
      }

      .center {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: center;
      }

      .right {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }

      .logo {
        //height: 67px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        padding: 7px 10px 10px 10px;
        //background-color: rgba(0, 0, 0, .4);

      }

      .menuButton {
        text-transform: capitalize;
        margin: 0 10px 0 0;

        .MuiButton-label {
          align-items: center;

        }

        .MuiSvgIcon-root {
          width: 20px;
          height: 20px;
          margin: 0 3px 0 0 ;
        }
      }
    }
  }

  .content {
    flex: 1;
    height: calc(100% - 64px);
  }
}
