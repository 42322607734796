.numberWidget {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .editButton {
    position: absolute;
    right: 10px;
    top: 9px;
  }

}

.editDialog {
  .content {
    min-width: 508px;

  }

  .actions {
    display: flex;
    padding: 8px 25px;
  }
}

