.batchView {
  height: 100%;
  width: 100%;

  ::-webkit-scrollbar {
    display: none;
  }

  .stepper {
    padding: 10px;

    .step {
      padding: 10px 10px;
      border-radius: 7px;

    }

    .completedStep {

      &:hover {
        background-color: rgba(255, 255, 255, .08);
      }
    }

    .tableSelection {
      padding: 0;

      .buttonTableSelection {
        padding: 7px;
        width: 60px;

      }
    }
  }

  .contentBatch {

    height: calc(100% - 66px);
    overflow: auto;


    .batch {
      padding: 10px;

      ::-webkit-scrollbar {
        display: block;
      }
    }
  }
}