@import '../../../App';

.machine {
  position: relative;
  min-width: 250px;
  min-height: 150px;
  text-align: center;
  border-radius: 20px !important;
  border: 2.5px solid #ab3022;
  //padding: 10px;
  height: 150px;

  @include media("<mobile") {
    flex: 1;
  }

  .machineName{
    font-size:2.5rem;
    margin: 10px;
    margin-bottom: unset;
  }

  .serialNumber{
    font-size:1rem;
    margin-top: 10px;
  }

  .content {
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border-radius: 19px;

    &:hover {
      background: rgba(255, 255, 255, .18);
    }
  }

  .actions {
    position: absolute;
    bottom: 5px;
    left: 5px;

    display: flex;
    flex-direction: row;
    width: calc(100% - 10px);
    height: 50px;

    & > * {
      flex: 1 0 25%;
      //height: max-content;
    }

    .iconButton {
      border-radius: 10px;


      & .icon {
        fill: white;
      }

      &:hover {
        & svg {
          fill: #f44336;
        }
      }
    }
  }
}
