.userSettingsPopUp{

  .title{

  }

  .content{
    display: flex;
    flex-direction: column;

    .textField{
      min-width: 300px;
    }

    .action{
      margin: 10px 0 0 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      button{
        margin: 0 20px 0 0;
        flex: 1;

        &:last-child{
          margin: 0;
        }
      }
    }
  }

}